<!-- // 注册页面 -->
<template>
  <div class="login">
    <div class="login-top">
      <div class="loginTopImage">
        <div class="loginTopImageTu"></div>
      <div class="textLoginNaver">
        <div class="loginTopImageBj">
        </div>
      </div>
      </div>
    </div>
    <van-form  class="login-bottom" @submit="onSubmit">
      <van-field
        v-model="mobile"
        name="pattern"
        left-icon="phone-o"
        placeholder="请输入手机号"
        clearable
        :rules="[{ required: true, pattern}]"
      />
      <van-field
        v-model="passCode"
        type=""
        left-icon="closed-eye"
        placeholder="请输入验证码"
        clearable
        minlength = 6
        :rules="[{ required: true }]"
      >
       <template #button>
            <span class="codeBotton">获取验证码</span>
          </template>
    </van-field>
        <van-button  block native-type="submit">注册</van-button>
    </van-form>
    <div class="bottomToB">
      <div class="bottomToBNaver">
        <div class="anImage"></div>
        <div class="textBt">安卓下载</div>
      </div>
     <div class="bottomToBNaver">
      <div class="iosImage"></div>
      <div class="textBt">苹果下载</div>
     </div>
 
    </div>
    </div>
    
</template>
<script>

export default {
  data() {
    return {
      mobile:'',
      passCode:'',
      pattern:/^[1][3-9]\d{9}$/
    };
  },
  created() {},
  mounted() {},
  methods: {
  // 提交按钮
  onSubmit(){

  }
  },
};
</script>
<style lang="less" scoped>
.login{
  // position: relative;
  // height: 100vh;
  padding-bottom: 60px;
}
.login-top{
  height: 350px;
  display: flex;
  justify-content: center;
  background: linear-gradient(45deg, #FF5604,#FCB825,);
  .loginTopImage{
    margin-top: 50px;
    .loginTopImageTu{
      width: 69px;
      height: 78px;
      background-image: url('../../assets/registerLogin.png');
      background-repeat: no-repeat;
        background-size: cover;
    }
    .loginTopImageBj{
      width: 78px;
      height: 34px;
      background-image: url('../../assets/textLogin.png');
      background-repeat: no-repeat;
        background-size: cover;
    }
  }
  .textLoginNaver{
    margin-top: 10px;
  }
}
.login-bottom{
  position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin: 0 auto;

  width: 320px;
  height: 240px;
  margin-top: -90px;
  padding: 20px 0  10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 18px 0px rgba(0,0,0,0.08);
}
.van-cell{
  position: relative;
  margin-top: 30px;
}
.van-button{
  position: absolute;
  bottom: 0;
 color: #ffa54f
}
.codeBotton{
 font-size: 12px; 
 color: #ffa54f
}
.bottomToB{
  // width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  .anImage{
    width: 54px;
    height: 61px;
    background-image: url('../../assets/anirond.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .iosImage{
    width: 54px;
    height: 61px;
    background-image: url('../../assets/pingguo.png');
    background-repeat: no-repeat;
  background-size: cover;

  }
  .bottomToBNaver{
    margin: 0 40px;
    color: #222222;
    text-align: -webkit-center;
    font-size: 16px;
    font-weight: 400;
    .textBt{
      margin-top: 10;
    }
  }
}
</style>
